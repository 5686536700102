import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  DesktopPopupModal,
  Header as GenericHeader,
  HeaderHomePageRightSection,
  HeaderMenuLinkType,
  IconName,
  Icon,
  ActionLink,
  ActionButton,
  getTravelCreditAndOfferTitle,
  CorpSurveyModal,
} from "halifax";
import {
  Switch,
  Route,
  RouteComponentProps,
  matchPath,
} from "react-router-dom";
import {
  B2B_PORTAL_ENDSESSION_PATH,
  CorpSessionInfo,
  CustomEvents,
  DropdownAction,
  EditTravelerModalState,
  PORTAL_SWITCHED,
  PortalSwitchedProperties,
  PortalTypes,
  RewardsAccount,
  SELECT_EDIT_TRAVELERS,
  SelectEditTravelersProperties,
  VIEWED_CONTACT_MODAL,
  WalletSummaryResponse,
  CorpBusinessAccount,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
} from "redmond";
import {
  useExperimentsById,
  MaintenanceBanner,
  useExperimentIsVariant,
} from "@capone/experiments";

import { isCorpTenant, PATH_CORP_FAQ, useUserContext } from "@capone/common";
import { fetchTravelWalletOffers } from "../../../../api/v1/takeovers/fetchTravelWalletOffers";
import CorporateLogo from "../../../../assets/b2b/capone-corporate-logo.svg";
import DBCCorporateLogo from "../../../../assets/b2b/capone-corporate-logo-dbc.svg";
import * as textConstants from "../../../constants";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TRAVEL_WALLET_CREDIT_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { CAP_ONE_CORP_LOGO_ALT } from "../../../../utils/constants";
import {
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_CARS,
  PATH_TRIPS,
  PATH_TERMS,
  PATH_TERMS_OF_SERVICE,
  PATH_SITE_TERMS_OF_USE,
  PATH_HOME,
  PATH_PREMIER_COLLECTION,
  PATH_TRAVEL_WALLET,
  PATH_TRAVEL_SALE,
  HIDDEN_FUNNEL_ENTRY_HEADER_PATHS,
  PATH_AUTH_INVALID_SESSION,
  PATH_CORP_ACCESS_DENIED,
  PATH_CORP_ACCESS_DENIED_NOT_BETA,
  PATH_CORP_NOT_LIVE,
  UNPROTECTED_PATHS,
  HIDDEN_HEADER_DETAILS_PATHS,
} from "../../../../utils/urlPaths";
import { trackEvent } from "../../../../api/v1/trackEvent";
import "./styles.scss";
import { SupportModalContent } from "../../../Header/components/SupportModal";
import { RewardsAccountSelection } from "../../../RewardsAccountSelection";
import { FunnelEntryTabs } from "../../../Header/components/FunnelEntryTabs";
import config from "../../../../utils/capone-corporate/config";
import {
  CorpAccountPickerModal,
  HAS_CHOSEN_ACCOUNT,
} from "../../MultiAccount/CorpAccountPickerModal/component";
import { changeBusinessInUse } from "../../../../api/v1/multi-account/changeBusinessInUse";
import { MULTI_ACCOUNT_MODAL_TITLE } from "./constants";
import { updateUserSeenModal } from "../../../../api/v1/user/updateUserSeenModal";

interface IHeaderProps {
  language: string;
  locationPath: string;
  rewardsAccounts?: RewardsAccount[];
  selectedRewardsAccountId: string | null;
  setSelectedRewardsAccountId: (id: string | null) => void;
  notAuthenticated?: boolean;
  canViewConsumer: boolean;
  corpBusinessAccounts?: CorpBusinessAccount[];
}

const DesktopHeader = ({
  locationPath,
  rewardsAccounts,
  selectedRewardsAccountId,
  setSelectedRewardsAccountId,
  notAuthenticated,
  canViewConsumer,
  corpBusinessAccounts,
}: IHeaderProps) => {
  const maintenanceExperiment = useExperimentsById("corp-traveler-maintenance");
  const isMaintenaceBannerActive =
    Boolean(maintenanceExperiment) &&
    maintenanceExperiment?.variant === "banner";
  const { sessionInfo } = useUserContext("capone-corporate");
  const [isAccountSwitchLoading, setIsAccountSwitchLoading] = useState(false);

  const CORP_HIDDEN_FUNNEL_ENTRY_HEADER_PATHS = [
    ...HIDDEN_FUNNEL_ENTRY_HEADER_PATHS,
    ...UNPROTECTED_PATHS,
  ];
  const [selectedSurveyOption, setSelectedSurveyOption] = useState(-1);

  const isNotLive = locationPath.includes(PATH_CORP_NOT_LIVE);
  const showDBCLogo =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const multiAccountExperiment =
    useExperimentsById("corp-multi-account-fe")?.variant === "available";

  const isSeanEllisQuestionEnabled = useExperimentIsVariant(
    "corp-sean-ellis-question",
    "available"
  );

  const [showCorpAccountPickModal, setShowCorpAccountPickModal] =
    useState(false);
  const [showSeanEllisQuestion, setShowSeanEllisQuestion] = useState(false);

  const handleCloseCorpAccountPickModal = () => {
    sessionStorage.setItem(HAS_CHOSEN_ACCOUNT, "true");

    setShowCorpAccountPickModal(false);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem(HAS_CHOSEN_ACCOUNT) != "true" &&
      multiAccountExperiment &&
      corpBusinessAccounts &&
      corpBusinessAccounts?.length > 1
    ) {
      setShowCorpAccountPickModal(true);
    }
  }, [corpBusinessAccounts, multiAccountExperiment]);

  useEffect(() => {
    const hasSeenSeanEllisQuestion = (sessionInfo as CorpSessionInfo)
      .corporateInfo.hasSeenModalMap["sean_ellis"];
    if (!hasSeenSeanEllisQuestion && isSeanEllisQuestionEnabled) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          type: "sean_ellis_question",
        },
      });
      setShowSeanEllisQuestion(true);
    }
  }, [isSeanEllisQuestionEnabled]);

  const surveyOptions = [
    {
      id: 1,
      label: "Very disappointed",
      tracking_property: "very_disappointed",
    },
    {
      id: 2,
      label: "Somewhat disappointed",
      tracking_property: "somewhat_disappointed",
    },
    {
      id: 3,
      label: "Not disappointed (it isn’t that useful)",
      tracking_property: "not_disappointed",
    },
  ];

  const handleSubmitSurvey = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        type: "sean_ellis_question",
        choice: surveyOptions[selectedSurveyOption - 1].tracking_property,
      },
    });
    updateUserSeenModal({
      modalName: "sean_ellis",
    })
      .then(() => {
        setShowSeanEllisQuestion(false);
      })
      .catch((e) => {
        console.error("Issue persisting seen sean ellis", e);
        setShowSeanEllisQuestion(false);
      });
  };

  const handleChangeSurveyOption = (optionId: number) => {
    setSelectedSurveyOption(optionId);
  };

  const handleCloseSurveyModal = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        type: "sean_ellis_question",
        choice: "close",
      },
    });
    updateUserSeenModal({
      modalName: "sean_ellis",
    })
      .then(() => {
        setShowSeanEllisQuestion(false);
      })
      .catch((e: Error) => {
        console.error("Issue persisting seen sean ellis", e);
        setShowSeanEllisQuestion(false);
      });
  };

  return (
    <>
      <GenericHeader
        className={clsx("desktop-header", "corp", {
          b2b: !notAuthenticated,
          "unprotected-header": notAuthenticated,
        })}
        fullWidth={true}
        left={
          <Switch>
            <Route
              path={[
                PATH_FLIGHTS,
                PATH_HOTELS,
                PATH_CARS,
                PATH_TRIPS,
                PATH_TERMS,
                PATH_TERMS_OF_SERVICE,
                PATH_SITE_TERMS_OF_USE,
                PATH_PREMIER_COLLECTION,
                PATH_TRAVEL_WALLET,
                PATH_TRAVEL_SALE,
                PATH_CORP_ACCESS_DENIED,
                PATH_CORP_ACCESS_DENIED_NOT_BETA,
                PATH_CORP_NOT_LIVE,
                PATH_CORP_FAQ,
              ]}
              render={(browserRouterProps: RouteComponentProps) => (
                <div
                  className={clsx("logo-container", {
                    disabled: isNotLive,
                  })}
                  onClick={() => {
                    if (isNotLive) return;
                    browserRouterProps.history.push(PATH_HOME);
                  }}
                >
                  <img
                    src={showDBCLogo ? DBCCorporateLogo : CorporateLogo}
                    alt={CAP_ONE_CORP_LOGO_ALT}
                  />
                </div>
              )}
            />
            <Route path="*" />
          </Switch>
        }
        toolbarClassName="b2b"
        right={
          notAuthenticated ? (
            <Switch>
              <Route
                path={[
                  PATH_FLIGHTS,
                  PATH_HOTELS,
                  PATH_CARS,
                  PATH_TRIPS,
                  PATH_TERMS,
                  PATH_TERMS_OF_SERVICE,
                  PATH_SITE_TERMS_OF_USE,
                  PATH_PREMIER_COLLECTION,
                  PATH_TRAVEL_WALLET,
                  PATH_TRAVEL_SALE,
                ]}
                render={(browserRouterProps: RouteComponentProps) => (
                  <CapOneHeaderHomePageRightSection
                    canViewConsumer={canViewConsumer}
                    notAuthenticated={notAuthenticated}
                    {...browserRouterProps}
                  />
                )}
              />
              <Route path="*" />
            </Switch>
          ) : rewardsAccounts &&
            !HIDDEN_HEADER_DETAILS_PATHS.includes(locationPath) &&
            rewardsAccounts.length > 0 ? (
            <RewardsAccountSelection
              selectedRewardsAccountId={selectedRewardsAccountId}
              setSelectedRewardsAccountId={setSelectedRewardsAccountId}
              rewardsAccounts={rewardsAccounts}
              corpBusinessAccounts={corpBusinessAccounts}
              activeAccountBusinessId={
                (sessionInfo as CorpSessionInfo).corporateInfo.businessId ?? ""
              }
              handleChangeBusinessInUse={(selectedBusinessId) => {
                setIsAccountSwitchLoading(true);
                changeBusinessInUse({
                  businessId: selectedBusinessId,
                })
                  .then((response) => {
                    setIsAccountSwitchLoading(false);
                    window.location.href = response.request.responseURL;
                  })
                  .catch((e) => {
                    setIsAccountSwitchLoading(false);
                    console.error("Issue changing business", e);
                  });
              }}
              isAccountSwitchLoading={isAccountSwitchLoading}
            />
          ) : undefined
        }
        middle={
          isMaintenaceBannerActive && (
            <MaintenanceBanner
              title={maintenanceExperiment.data.title}
              subtext={maintenanceExperiment.data.subtext}
              severity={maintenanceExperiment.data.severity}
            />
          )
        }
        bottom={
          notAuthenticated ? undefined : !CORP_HIDDEN_FUNNEL_ENTRY_HEADER_PATHS.includes(
              locationPath
            ) ? (
            <Switch>
              <Route
                path={[
                  PATH_FLIGHTS,
                  PATH_HOTELS,
                  PATH_CARS,
                  PATH_TRIPS,
                  PATH_TERMS,
                  PATH_TERMS_OF_SERVICE,
                  PATH_SITE_TERMS_OF_USE,
                  PATH_PREMIER_COLLECTION,
                  PATH_TRAVEL_WALLET,
                  PATH_TRAVEL_SALE,
                ]}
                render={(browserRouterProps: RouteComponentProps) => (
                  <FunnelEntryTabs {...browserRouterProps} />
                )}
              />
              <Route path="*" />
            </Switch>
          ) : undefined
        }
      />
      <CorpAccountPickerModal
        open={showCorpAccountPickModal}
        onClose={handleCloseCorpAccountPickModal}
        headerImage={<Icon name={IconName.MultiAccountCards} />}
        title={MULTI_ACCOUNT_MODAL_TITLE}
        accounts={corpBusinessAccounts ?? []}
        sessionInfo={sessionInfo as CorpSessionInfo}
      />
      {isCorpTenant(config.TENANT) && (
        <CorpSurveyModal
          isOpen={showSeanEllisQuestion}
          onClose={handleCloseSurveyModal}
          title={
            <Typography
              variant="h2"
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                lineHeight: "28px",
                marginBottom: "10px",
              }}
            >
              How would you feel if you could no longer use Capital One Travel
              for Business?
            </Typography>
          }
          subtitle={
            <Typography
              variant="body1"
              style={{
                fontSize: "16px",
                lineHeight: "19px",
                marginBottom: "24px",
                maxWidth: "80%",
              }}
            >
              Don't worry, we're not going anywhere! Answering helps us improve
              our product for valued customers like you.
            </Typography>
          }
          image={<Icon name={IconName.SurveyQuestionMark} />}
          options={surveyOptions}
          onSubmit={handleSubmitSurvey}
          onOptionChange={handleChangeSurveyOption}
          selectedOptionId={selectedSurveyOption}
        />
      )}
    </>
  );
};

interface ICapOneHeaderHomePageRightSectionProps extends RouteComponentProps {
  displayOnlyIcon?: boolean;
  notAuthenticated?: boolean;
  rewardsAccounts?: RewardsAccount[] | CorpBusinessAccount[];
  canViewConsumer: boolean;
}

export const CapOneHeaderHomePageRightSection = (
  props: ICapOneHeaderHomePageRightSectionProps
) => {
  const {
    displayOnlyIcon,
    notAuthenticated,
    rewardsAccounts,
    canViewConsumer,
    ...routerProps
  } = props;
  const { history } = routerProps;
  const { pathname } = history.location;
  const { sessionInfo } = useUserContext("capone-corporate");
  const expState = useExperiments();
  const [openContactModal, setOpenContactModal] = useState(false);
  const [offerCount, setOfferCount] = useState(0);
  const onCarsPage = matchPath(pathname, PATH_CARS);
  const onFlightsPage = matchPath(pathname, PATH_FLIGHTS);
  const onHotelsPage = matchPath(pathname, PATH_HOTELS);
  const onTripsPage = matchPath(pathname, PATH_TRIPS);
  const dropdownActions: DropdownAction[] = [];

  const isTravelWalletOfferExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_OFFER_EXPERIMENT
    ) === AVAILABLE;

  const isTravelWalletCreditsExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_CREDIT_EXPERIMENT
    ) === AVAILABLE;

  const onLogout = useCallback(() => {
    history.push(B2B_PORTAL_ENDSESSION_PATH);
  }, [history]);

  /**
   * @description Dispatches a custom event to communicate with Pawtucket
   * modules. This event is handled in flights/hotels/cars Body component.
   */
  const onEditTravelerClick = useCallback(
    (iconButton = false) => {
      let editTravelersModalState = EditTravelerModalState.closed;

      if (onCarsPage) {
        editTravelersModalState = EditTravelerModalState.ground;
      } else if (onFlightsPage) {
        editTravelersModalState = EditTravelerModalState.flight;
      } else if (onHotelsPage) {
        editTravelersModalState = EditTravelerModalState.hotel;
      } else if (onTripsPage) {
        editTravelersModalState = EditTravelerModalState.trips;
      }

      const event = new CustomEvent<any>(
        CustomEvents.editTravelersStateChange,
        {
          detail: editTravelersModalState,
        }
      );

      document.dispatchEvent(event);

      trackEvent({
        eventName: SELECT_EDIT_TRAVELERS,
        properties: {
          source: iconButton ? "traveler_nav" : "dropdown, header",
        } satisfies SelectEditTravelersProperties,
      });
    },
    [onCarsPage, onFlightsPage, onHotelsPage, onTripsPage]
  );

  const handleSupportModalClose = () => setOpenContactModal(false);

  // only show this action in product pages until edit travelers modal is ready
  // on other pages.
  if (onCarsPage || onFlightsPage || onHotelsPage) {
    dropdownActions.push({
      divider: true,
      label: "Edit Travelers",
      onClick: onEditTravelerClick,
    });
  }

  useEffect(() => {
    fetchTravelWalletOffers().then((response) => {
      const hasCredit = !!(response as WalletSummaryResponse).credit;
      const offerCount = (response as WalletSummaryResponse).offerCount || 0;
      const totalCount = hasCredit ? offerCount + 1 : offerCount;
      setOfferCount(totalCount);
    });
  }, []);

  const trackPortalSwitched = (portal: PortalTypes) => {
    trackEvent({
      eventName: PORTAL_SWITCHED,
      properties: {
        current_portal: "corporate",
        portal_selected: portal,
      } satisfies PortalSwitchedProperties,
    });
  };

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const links = useMemo(
    () => [
      ...(isTravelWalletCreditsExperiment
        ? [
            {
              type: HeaderMenuLinkType.Wallet,
              selected: history.location.pathname === PATH_TRAVEL_WALLET,
              content: (
                <Typography className="my-travel-offers-link">
                  {getTravelCreditAndOfferTitle(!isCorpHideTravelOffers)}
                </Typography>
              ),
              onClick: () => {
                routerProps.history.push(PATH_TRAVEL_WALLET);
              },
              badgeContent: offerCount,
              displayOnlyIcon: displayOnlyIcon,
            },
          ]
        : isTravelWalletOfferExperiment
        ? [
            {
              type: HeaderMenuLinkType.Offer,
              selected: history.location.pathname === PATH_TRAVEL_WALLET,
              content: (
                <Typography className="my-travel-offers-link">
                  My travel offers
                </Typography>
              ),
              onClick: () => {
                routerProps.history.push(PATH_TRAVEL_WALLET);
              },
              badgeContent: offerCount,
              displayOnlyIcon: displayOnlyIcon,
            },
          ]
        : []),
      {
        type: HeaderMenuLinkType.Trips,
        selected: history.location.pathname === PATH_TRIPS,
        content: <Typography className="my-trips-link">My Trips</Typography>,
        onClick: () => {
          routerProps.history.push(PATH_TRIPS);
        },
        dropdownActions: [],
        displayOnlyIcon: displayOnlyIcon,
      },
      {
        type: HeaderMenuLinkType.Travelers,
        selected: false,
        content: <Typography>Traveler profiles</Typography>,
        onClick: () => onEditTravelerClick(true),
        dropdownActions: [],
        displayOnlyIcon: displayOnlyIcon,
      },
      {
        type: HeaderMenuLinkType.Support,
        selected: false,
        content: <Typography>{textConstants.SUPPORT_LINK}</Typography>,
        onClick: () => {
          trackEvent({
            eventName: VIEWED_CONTACT_MODAL,
            properties: { contact_reason: "general_contact" },
          });
          setOpenContactModal(true);
        },
        dropdownActions: [],
        displayOnlyIcon: displayOnlyIcon,
      },
      {
        type: HeaderMenuLinkType.CorporateMenu,
        selected: false,
        content: displayOnlyIcon ? (
          <>
            <Icon className="dropdown-icon" name={IconName.BriefcaseIcon} />
            <Typography>Business travel</Typography>
          </>
        ) : (
          <Typography>{`Welcome, ${sessionInfo?.userInfo?.firstName}`}</Typography>
        ),
        onClick: onLogout,
        dropdownActions: [
          {
            label: `${sessionInfo?.userInfo?.firstName} ${sessionInfo?.userInfo?.lastName}`,
            onClick: onEditTravelerClick,
          },
        ],
      },
    ],
    [
      displayOnlyIcon,
      history.location.pathname,
      isTravelWalletCreditsExperiment,
      isTravelWalletOfferExperiment,
      offerCount,
      onEditTravelerClick,
      onLogout,
      routerProps.history,
      sessionInfo?.userInfo?.firstName,
      sessionInfo?.userInfo?.lastName,
    ]
  );

  const UnprotectedPathHeaderRightSection = () => {
    return (
      <Box className="non-auth-header-right-section-container">
        <Box className="non-auth-header-right-section-support">
          <Typography className="support-text">
            {textConstants.QUESTIONS_ABOUT_CAP1_TRAVEL}
          </Typography>
          <ActionLink
            content={
              <Typography className="support-modal-link-text">
                {textConstants.GET_HELP}
              </Typography>
            }
            onClick={() => {
              trackEvent({
                eventName: VIEWED_CONTACT_MODAL,
                properties: { contact_reason: "general_contact" },
              });
              setOpenContactModal(true);
            }}
            className="support-modal-link"
          />
        </Box>
        <ActionButton
          onClick={() => {
            history.push(PATH_AUTH_INVALID_SESSION);
          }}
          message={textConstants.SIGN_IN}
          className="b2b non-auth-login-button"
        />
      </Box>
    );
  };
  return (
    <>
      {notAuthenticated ? (
        <UnprotectedPathHeaderRightSection />
      ) : (
        <HeaderHomePageRightSection
          links={links}
          {...routerProps}
          className="b2b"
          consumerPortalUrl={config.consumerPortalUrl}
          adminPortalUrl={config.adminPortalUrl}
          canViewAdmin={
            (sessionInfo as CorpSessionInfo)?.corporateInfo.permissions
              .canViewAdmin
          }
          canViewConsumer={canViewConsumer}
          tenant={"capone-corporate"}
          trackPortalSwitched={trackPortalSwitched}
        />
      )}
      <DesktopPopupModal
        open={openContactModal}
        className="desktop-contact-support-popup"
        onClose={handleSupportModalClose}
        invisibleBackdrop={false}
      >
        <Box className="desktop-contact-support-popup-content">
          <SupportModalContent />
        </Box>
      </DesktopPopupModal>
    </>
  );
};

export default DesktopHeader;
